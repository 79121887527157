import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../lib/graphql-error-list'
import InViewMonitor from 'react-inview-monitor'
import { gsap, TimelineMax, tweenTarget, TweenMax } from 'gsap'
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin'
import ReactFullpage from '@fullpage/react-fullpage'
import get from 'lodash/get'
import { motion, AnimatePresence } from 'framer-motion'

import SEO from '../components/scaffolding/seo'
import CaseStudyPopup from '../components/modules/caseStudyPopup'
import Module from '../components/scaffolding/module'
import Header from '../components/header'
import Footer from '../components/footer'

import newsImg from '../img/shapes/news-bg.svg'

if (typeof window !== `undefined`) {
  gsap.install(window)
  gsap.registerPlugin(MorphSVGPlugin)
  gsap.core.globals('MorphSVGPlugin', MorphSVGPlugin)
}

const morphMap = [
  {
    morphEnd: '#servicesBlank',
    fill: '#fff',
    opacity: 0,
  },
  {
    morphEnd: '#servicesHero',
    fill: '#219E45',
    opacity: 1,
  },
  {
    morphEnd: '#servicesOne',
    fill: '#529EBF',
    opacity: 1,
  },
  {
    morphEnd: '#servicesTwo',
    fill: '#8C17E0',
    opacity: 1,
  },
  {
    morphEnd: '#servicesThree',
    fill: '#00BA99',
    opacity: 1,
  },
  {
    morphEnd: '#servicesFour',
    fill: '#FA7500',
    opacity: 1,
  },
  {
    morphEnd: '#servicesFive',
    fill: '#219E45',
    opacity: 1,
  },
  {
    morphEnd: '#servicesSix',
    fill: '#ACE0BE',
    opacity: 1,
  },
]

class PageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      paused: false,
      popupOpen: false,
      popupDetail: null,
    }
    this._setPopup = this._setPopup.bind(this)
    this._closePopup = this._closePopup.bind(this)
    this._jumpToSlide = this._jumpToSlide.bind(this)
    this.tl = new TimelineMax()
  }
  _setPopup(e) {
    e && this.setState({ popupDetail: e, popupOpen: true })
  }
  _closePopup(e) {
    this.setState({ popupOpen: false })
  }
  _jumpToSlide(idx) {
    fullpage_api.moveTo(idx)
  }

  _handleChange(origin, destination, direction) {
    let { index } = destination
    let duration = 1
    this.setState({ index: index })
    if (index < morphMap.length) {
      this.tl.to('#servicesBlank', duration, {
        morphSVG: morphMap[index].morphEnd,
        fill: morphMap[index].fill,
        fillOpacity: morphMap[index].opacity,
      })
    }
  }
  render() {
    const { index, paused, popupOpen = false, popupDetail } = this.state
    const page = this.props.data.page && get(this, 'props.data.page')
    let mobile
    if (typeof document !== `undefined`) {
      mobile = document.documentElement.clientWidth <= 1024
    }

    const svgBg = (
      <svg
        id="servicesSvg"
        fill="none"
        viewBox={mobile ? '0 0 300 900' : '0 0 1 800'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="servicesBlank"
          d="m0 0v800h599l-199.662-800z"
          fill="#ffffff"
          fillOpacity="0"
        />
        <rect id="servicesHero" width="800" height="800" fill="#219E45" />
        <path id="servicesOne" d="M0 0V800L800 0H0Z" fill="#529EBF" />
        <path id="servicesTwo" d="M200 0L0 800H600V0H200Z" fill="#8C17E0" />
        <path
          id="servicesThree"
          d="M400 0L0 0L0 800L200 800L400 0Z"
          fill="#00BA99"
        />
        <path
          id="servicesFour"
          d="M0 400L0 0L800 0V200L0 400Z"
          fill="#FA7500"
        />
        <path id="servicesFive" d="M0 0V600L800 0H0Z" fill="#219E45" />
        <path id="servicesSix" d="M0 0V800L800 600V0H0Z" fill="#ACE0BE" />
      </svg>
    )

    return (
      <>
        {page.isScrollJacked && page.hasStations && (
          <div className="bgImg">{svgBg}</div>
        )}

        {this.props.errors ? (
          <SEO title="GraphQL Error" />
        ) : (
          <SEO
            title={page.title}
            description={page.seo ? page.seo.description : ''}
            shareGraphicURL={
              page.seo && page.seo.share ? page.seo.share.asset.fixed.src : null
            }
          />
        )}
        {this.props.errors && <GraphQLErrorList errors={this.props.errors} />}

        <Header
          active={page.slug.current}
          location={this.props.location}
          mobile={mobile}
          jump={this._jumpToSlide}
          expanded={!mobile && index === 0}
        />

        <main role="main" id="content">
          <AnimatePresence exitBeforeEnter>
            {popupOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
              >
                <CaseStudyPopup
                  mobile={mobile}
                  module={popupDetail}
                  isOpen={popupOpen}
                  close={this._closePopup}
                />
              </motion.div>
            )}
          </AnimatePresence>

          {page.title === 'News' && (
            <>
              <div className="newsPageSpacer" />
              <div className="newsPageImg">
                <img src={newsImg} alt="" />
              </div>
            </>
          )}
          {page.isScrollJacked && (
            <ReactFullpage
              scrollingSpeed={1000}
              licenseKey={process.env.GATSBY_FULLPAGE_KEY}
              onLeave={this._handleChange.bind(this)}
              easing={'easeInOutCubic'}
              render={(fullPageApi) => {
                return (
                  <ReactFullpage.Wrapper>
                    {page.content.map((module, key) => {
                      return (
                        <Module
                          key={key}
                          page={page}
                          module={module}
                          mobile={mobile}
                          popupOpen={popupOpen}
                          callback={this._setPopup}
                          jump={this._jumpToSlide}
                          isScrollJacked={page.isScrollJacked}
                        />
                      )
                    })}
                    <Footer softFooter />
                  </ReactFullpage.Wrapper>
                )
              }}
            />
          )}
          {!page.isScrollJacked &&
            page.content.map((module, key) => {
              return (
                <div key={key}>
                  <InViewMonitor
                    key={key}
                    classNameNotInView="v-hide"
                    classNameInView="fade-in-up"
                  >
                    <Module
                      page={page}
                      module={module}
                      mobile={mobile}
                      isScrollJacked={page.isScrollJacked}
                    />
                  </InViewMonitor>
                </div>
              )
            })}
          <Footer homePage={false} jump={this._jumpToSlide} />
        </main>
      </>
    )
  }
}

export default PageTemplate

export const homePageQuery = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      publishedAt
      isScrollJacked
      hasStations
      title
      slug {
        current
      }
      _rawContent
      content {
        ... on SanityServicesHero {
          _key
          _type
          subtitle
          title
        }
        ... on SanityCaseStudyGap {
          _key
          _type
          ctaLink
          ctaTitle
          title
        }
        ... on SanitySoftFooter {
          _key
          _type
          title
        }
        ... on SanityWhoWeAre {
          _key
          _type
          title
        }
        ... on SanityContact {
          _key
          _type
          jobTitle
          email
          name
          phone
          text
          image {
            _key
            _type
            alt
            asset {
              fluid {
                src
              }
            }
          }
        }
        ... on SanityServices {
          _key
          _type
          caseStudies {
            _key
            _type
            company
            color
            name
            quote
            title
            subtitle
            text
            result
            press {
              publisher
              title
              url
            }
            image {
              asset {
                fluid {
                  src
                }
              }
            }
          }
          title
          description
        }
        ... on SanityCaseStudies {
          _key
          _type
          company
          color
          name
          quote
          title
          subtitle
          text
          result
          press {
            publisher
            title
            url
          }
          image {
            asset {
              fluid {
                src
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityHero {
          _key
          _type
          title
          shape {
            asset {
              fluid {
                src
              }
            }
            alt
          }
        }
        ... on SanityHeroVideo {
          _key
          _type
          title
          video
        }
        ... on SanityNews {
          _key
          _type
          publisher
          title
          snippet
          url
          shape {
            asset {
              fluid {
                src
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
        }
      }
      seo {
        description
        share {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`
